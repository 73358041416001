import { useState } from 'react';
import { getCall, postCall } from 'services/v2/Services';
import useGetApiTokens from './useGetApiTokens';
import { POST_ASSIGNABLE_LIST } from 'services/v2/BusinessRulesAPI';
import { IAssignableCriteriaList, IAssignableSummary } from 'types/BusinesRuleType';

const ITEM_TYPE_FRUIT: string = 'fruit';
const ACTIVE: number = 1;

const useAddRowFields = (oktaAuth: any) => {
    const { getTokenAndId } = useGetApiTokens();
    const [producingAreaError, setProducingAreaError] = useState(null);
    const [producingAreaLoading, setProducingAreaLoading] = useState(false);
    const [producingArea, setProducingArea] = useState([] as any[]);
    const [assignmentList, setAssignmentList] = useState<IAssignableCriteriaList>();
    const [assignmentListForItems, setAssignmentListForItems] = useState<IAssignableCriteriaList>();
    const [filteredSummaryData, setFilteredSummaryData] = useState<IAssignableSummary>();

    const [growerError, setGrowerError] = useState(null);
    const [growerLoading, setGrowerLoading] = useState(false);
    const [grower, setGrower] = useState([] as any[]);

    const [ranchError, setRanchError] = useState(null);
    const [ranchLoading, setRanchLoading] = useState(false);
    const [ranch, setRanch] = useState([] as any[]);

    const [itemError, setItemError] = useState(null);
    const [itemLoading, setItemLoading] = useState(false);
    const [item, setItem] = useState([] as any[]);
    const [brands, setBrands] = useState([] as any[]);
    const [fieldTypes, setFieldTypes] = useState([] as any[]);
    const [berryTypes, setBerryTypes] = useState([] as any[]);
    const [filteredItemLoaded, setFilteredItemLoaded] = useState(false);

    const getUniqueListBy = (arr: any, key: string) => {
        return [...new Map(arr.map((item) => [item[key], item])).values()];
    };

    const getFilteredAssignmentForSummary = async (payload: any) => {
        setProducingAreaLoading(true);
        setRanchLoading(true);
        setItemLoading(true);
        const startTime = performance.now();
        const { token } = await getTokenAndId(oktaAuth);

        try {
            const response = await postCall(POST_ASSIGNABLE_LIST(), token, {}, payload);
            if (response && response.errors && response.errors.length > 0) {
                throw response.errors[0];
            } else {
                const data = response.display.Data?.[0];

                const ranches = (data.AssignableRanchList || []).map((item) => {
                    return {
                        RanchNbr: item.RanchNbr,
                        RanchName: item.RanchName,
                        GrowerName: item.GrowerName,
                        ProducingAreaCode: item.ProducingAreaCode
                    };
                });
                const items = (data.AssignableItemList || []).map((item) => {
                    return {
                        ItemName: item.ItemName,
                        ItemNbr: item.ItemNbr,
                        FieldType: item.FieldType,
                        Brand: item.Brand,
                        BerryType: item.BerryType
                    };
                });
                setFilteredSummaryData({
                    Items: items,
                    Ranches: ranches
                });
            }
        } catch (err) {
            setProducingAreaError(err);
        } finally {
            setProducingAreaLoading(false);
            setRanchLoading(false);
            setItemLoading(false);
        }
    };

    const getAssignableList = async (
        payload: any,
        loadItems: boolean = false,
        itemSelected: boolean,
        ranchSelected: boolean,
        isSettlementPool: boolean
    ) => {
        setProducingAreaLoading(true);
        setRanchLoading(true);
        setItemLoading(true);
        const startTime = performance.now();
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const response = await postCall(POST_ASSIGNABLE_LIST(), token, {}, payload);
            if (response && response.errors && response.errors.length > 0) {
                throw response.errors[0];
            } else {
                const data = response.display.Data?.[0];
                getAssignableData(data, loadItems, itemSelected, ranchSelected, isSettlementPool);
            }
        } catch (err) {
            setProducingAreaError(err);
        } finally {
            setProducingAreaLoading(false);
            setRanchLoading(false);
            setItemLoading(false);
        }
    };

    const getAssignableData = (
        data: IAssignableCriteriaList,
        loadItems: boolean = false,
        itemSelected: boolean,
        ranchSelected: boolean,
        isSettlementPool: boolean = false
    ) => {
        if (!loadItems) {
            setAssignmentList(data);
        } else {
            setAssignmentListForItems(data);
        }

        const producingArea = (data.AssignableRanchList || [])
            .map((item) => {
                return { ProducingAreaCode: item.ProducingAreaCode, ProducingAreaName: item.ProducingAreaName };
            })
            .sort((first, next) => parseInt(first?.ProducingAreaCode) - parseInt(next?.ProducingAreaCode));
        const growers = (data.AssignableRanchList || [])
            .map((item) => {
                return { GrowerNbr: item.GrowerNbr, GrowerName: item.GrowerName, ProducingAreaCode: item.ProducingAreaCode };
            })
            .sort((first, next) => parseInt(first?.GrowerNbr) - parseInt(next?.GrowerNbr));
        const ranches = (data.AssignableRanchList || []).map((item) => {
            return {
                FieldType: item.FieldType,
                BerryType: item.BerryType,
                RanchNbr: item.RanchNbr,
                RanchName: item.RanchName,
                GrowerName: item.GrowerName,
                GrowerNbr: item.GrowerNbr,
                ProducingAreaCode: item.ProducingAreaCode
            };
        });
        const items = (data.AssignableItemList || []).map((item) => {
            return {
                ItemName: item.ItemName,
                ItemNbr: item.ItemNbr,
                FieldType: item.FieldType,
                Brand: item.Brand,
                BerryType: item.BerryType
            };
        });
        const brands = getUniqueListBy(
            items
                .map((item) => {
                    return { value: item.Brand || '', label: item.Brand };
                })
                .filter((item) => item.value !== ''),
            'value'
        );
        const fieldType = getUniqueListBy(
            items
                .map((item) => {
                    return { value: item.FieldType || '', label: item.FieldType };
                })
                .filter((item) => item.value !== ''),
            'value'
        );

        const berryTypes = getUniqueListBy(
            items.map((item) => {
                return { value: item.BerryType || '', label: item.BerryType };
            }),
            'value'
        );
        if (itemSelected || !loadItems || isSettlementPool) {
            setProducingArea(producingArea);
            setGrower(growers);
            setRanch(ranches);
        }
        if (ranchSelected || !loadItems || isSettlementPool) {
            setBrands(brands);
            setBerryTypes(berryTypes);
            setFieldTypes(fieldType);
            setItem(items);
        }
    };

    const getProducingArea = async (pagesize, startpage, berryType, fieldType, loadFromAssignemnts) => {
        const sourceDataList: IAssignableCriteriaList = loadFromAssignemnts ? assignmentListForItems : assignmentList;
        let producingArea = (sourceDataList?.AssignableRanchList || []).map((item) => {
            return { ProducingAreaCode: item.ProducingAreaCode, ProducingAreaName: item.ProducingAreaName };
        });
        setProducingArea(producingArea);
    };

    const getGrower = async (pagesize, startpage, data, loadFromAssignemnts) => {
        const sourceDataList: IAssignableCriteriaList = loadFromAssignemnts ? assignmentListForItems : assignmentList;
        let growers = (sourceDataList?.AssignableRanchList || [])
            .map((item) => {
                return {
                    GrowerNbr: item.GrowerNbr,
                    GrowerName: item.GrowerName,
                    ProducingAreaCode: item.ProducingAreaCode,
                    FieldType: item.FieldType,
                    BerryType: item.BerryType
                };
            })
            .filter((item) => {
                return (
                    data.ProducingAreaCodes?.includes(item.ProducingAreaCode) && (data.BerryType ? data.BerryTypes?.includes(item.BerryType) : true)
                );
            });
        setGrower(growers);
    };

    const getRanch = async (pagesize, startpage, data, loadFromAssignemnts) => {
        const sourceDataList: IAssignableCriteriaList = loadFromAssignemnts ? assignmentListForItems : assignmentList;
        let ranches = (sourceDataList?.AssignableRanchList || [])
            .map((item) => {
                return {
                    FieldType: item.FieldType,
                    BerryType: item.BerryType,
                    RanchNbr: item.RanchNbr,
                    RanchName: item.RanchName,
                    GrowerName: item.GrowerName,
                    GrowerNbr: item.GrowerNbr,
                    ProducingAreaCode: item.ProducingAreaCode
                };
            })
            .filter((item) => {
                return (
                    (data.ProducingAreaCodes?.length > 0 ? data.ProducingAreaCodes?.includes(item.ProducingAreaCode) : true) &&
                    (data.GrowerNbrs?.length > 0 ? data.GrowerNbrs?.includes(item.GrowerNbr) : true) &&
                    (data.BerryType?.length > 0 ? data.BerryTypes?.includes(item.BerryType) : true)
                );
            });
        announceBerryTypes(ranches);
        setRanch(ranches);
    };

    const getItem = async (pagesize, startpage, data, loadFromAssignemnts) => {
        const sourceDataList: IAssignableCriteriaList = loadFromAssignemnts ? assignmentListForItems : assignmentList;
        let items = (sourceDataList?.AssignableItemList || [])
            .map((item) => {
                return {
                    ItemName: item.ItemName,
                    ItemNbr: item.ItemNbr,
                    FieldType: item.FieldType,
                    Brand: item.Brand,
                    BerryType: item.BerryType
                };
            })
            .filter((item) => {
                return data.brands?.includes(item.Brand) && data.berryTypes?.includes(item.BerryType) && data.packageTypes?.includes(item.FieldType);
            });
        announceBerryTypes(items);
        setItem(items);
    };

    const announceBerryTypes = (data: any) => {
        getUniqueListBy(data, 'BerryType').forEach((element: any) => {
            console.log(element.BerryType);
        });
    };

    const resetDependency = (optionName: 'producingArea' | 'grower' | 'ranch' | 'item') => {
        switch (optionName) {
            case 'producingArea':
                setGrower([]);
            case 'grower':
                setRanch([]);
            case 'ranch':
                setItem([]);
            case 'item':
                setItem([]);
            default:
                return;
        }
    };

    return {
        getAssignableList,
        getAssignableData,
        assignmentList,
        producingArea,
        producingAreaError,
        producingAreaLoading,
        getProducingArea,
        grower,
        growerError,
        growerLoading,
        getGrower,
        ranch,
        ranchError,
        ranchLoading,
        getRanch,
        item,
        itemError,
        itemLoading,
        getItem,
        resetDependency,
        brands,
        fieldTypes,
        berryTypes,
        filteredSummaryData,
        getFilteredAssignmentForSummary
    };
};
export default useAddRowFields;
