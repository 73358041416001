import { withOktaAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import { withRouter } from 'routes/withRouter';
import { setPageTitleAction, showToast } from 'actions/actions';
import { setSettlementWorkbenchData } from 'actions/settlementWorkbenchAction';
import DrcWrapper from 'components/DrcWrapper';
import { DetailPage, syncTranslations } from '@driscollsinc/ggs-workbench-module-ui';
import useSettlement from 'hooks/v2/useSettlement';
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';
import Stepper from 'components/Stepper';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSettlementMasterData from 'hooks/v2/useSettlementMasterData';
import { Category } from '@mui/icons-material';
import DuExcelUtilities from 'utils/excelUtility';
import { Breadcrumbs, Typography } from '@mui/material';
import { designColors } from 'data/constants';

const getUniqueListBy = <T,>(arr: T[], key: keyof T) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
};

function WorkbenchDetails(props) {
    const { getAggregate, getFilterDropdownValues, getPoolWeeksByDate } = useSettlement(props.oktaAuth);
    const { state } = useLocation();
    const [params, setSearchParams] = useSearchParams();
    const { i18n } = useTranslation();
    const [tabs, setTabs] = useState<string[]>([]);
    const [filters, setFilters] = useState<{ [key: string]: string }>({});
    const [poolWeek, setPoolWeek] = useState(params.get('poolWeek'));
    const [assingableList, setAssignableList] = useState([]);
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [filteredList, setFilteredList] = useState([]);
    const [yearPoolWeeks, setYearPoolweeks] = useState([]);
    const firstRender = useRef(true);

    const pageTitle = 'Workspace Details';
    const pagesize = 1000;
    const startpage = 1;
    const ranchPagesize = 3500;

    useEffect(() => {
        if (props.pageTitle !== pageTitle) {
            props.setPageTitle(pageTitle);
        }
        firstRender.current = false;
        setLoadingOptions(true);
        getPoolWeeksByDate().then((yearPoolWeeks) =>
            setYearPoolweeks(getUniqueListBy([...yearPoolWeeks.data.sort((a, b) => b.PoolWeek - a.PoolWeek)], 'PoolWeek'))
        );
        getFilterDropdownValues(poolWeek, params.get('PFQPool'), params.get('PricingPool'), '', '', '')
            .then((data) => {
                setLoadingOptions(false);
                return setAssignableList(data ?? []);
            })
            .catch(() => setLoadingOptions(false));
    }, []);

    useEffect(() => {
        setFilteredList(() => {
            let newFilteredList = assingableList;
            if (filters.ProducingAreaCode) newFilteredList = newFilteredList.filter((item) => filters.ProducingAreaCode === item.ProducingAreaCode);
            if (filters.GrowerNbr) newFilteredList = newFilteredList.filter((item) => filters.GrowerNbr === item.GrowerNbr);
            if (filters.RanchNbr) newFilteredList = newFilteredList.filter((item) => filters.RanchNbr === item.RanchNbr);
            return newFilteredList;
        });
    }, [filters, assingableList]);

    useEffect(() => {
        syncTranslations(i18n.language);
    }, [i18n.language]);

    const exportAsExcel = (rows) => {
        const excelColumns = rows.headers.map((col: any) => ({
            key: col.key,
            name: col.Name
        }));
        DuExcelUtilities.Write('GGS-Settlement_Detail_PW' + poolWeek + '_Data.xlsx', excelColumns, rows);
    };

    return (
        <DrcWrapper>
            <div style={{ padding: '0 20px' }}>
                <DetailPage
                    breadcrumbs={
                        <Breadcrumbs sx={{ color: 'black', '@media (prefers-color-scheme: dark)': { color: `#a7a7a7 !important` } }}>
                            <Typography sx={{ color: designColors.blue, '@media (prefers-color-scheme: dark)': { color: 'white' } }} fontWeight={500}>
                                Home
                            </Typography>
                            <Typography>Workspace</Typography>
                            <Typography>Detail Page</Typography>
                        </Breadcrumbs>
                    }
                    onExport={(data) => exportAsExcel({ data: data?.rows, headers: data?.headers })}
                    defaultWeek={poolWeek}
                    availablePoolWeeks={yearPoolWeeks.map((yr) => ({
                        week: yr.PoolWeek,
                        startDate: yr.PoolWeekStartDate,
                        endDate: yr.PoolWeekEndDate
                    }))}
                    renderer={{
                        Category: (data, original) => {
                            const isHyperLink = original.Hyperlink === 1;
                            return (
                                <>
                                    {isHyperLink ? (
                                        <Link
                                            style={{ textDecoration: 'none', color: 'var(--blue-400)' }}
                                            to={`../Breakdown?label=${data}&poolWeek=${poolWeek}&PricingPool=${params.get(
                                                'PricingPool'
                                            )}&PFQPool=${params.get('PFQPool')}`}
                                            state={{ ...state, tabs, filters }}
                                        >
                                            {data}
                                        </Link>
                                    ) : (
                                        <Typography>{data}</Typography>
                                    )}
                                </>
                            );
                        },
                        'Amount (USD)': (data) => {
                            const numValue = parseFloat(data as string);
                            const isNegative = numValue < 0;
                            return <span style={{ color: isNegative && 'var(--red-400)' }}>{numValue.toLocaleString('en')}</span>;
                        }
                    }}
                    getData={async (filters) => {
                        const data = await getAggregate({
                            PoolWeek: filters.poolWeek,
                            PricingPool: filters.PricingPool,
                            PFQPool: filters.PFQPool,
                            ProducingAreaCode: filters.producingArea?.value,
                            RanchNbr: filters.ranch?.value,
                            GrowerNbr: filters.grower?.value
                        });
                        setTabs(data.rows.filter((d) => d.Hyperlink === 1).map((d) => d.Category));
                        setPoolWeek(filters.poolWeek);
                        return data;
                    }}
                    breakdownRoute={{ to: '../Breakdown', options: { state: { tabs, filters, ...state } } }}
                    options={{
                        producingArea: getUniqueListBy(
                            filteredList.map((p) => ({
                                label: `${p.ProducingAreaCode}-${p.ProducingAreaName}`,
                                value: p.ProducingAreaCode
                            })),
                            'value'
                        ),
                        grower: getUniqueListBy(
                            filteredList.map((g) => ({ label: `${g.GrowerNbr}-${g.GrowerName}`, value: g.GrowerNbr })),
                            'value'
                        ),
                        ranch: getUniqueListBy(
                            filteredList.map((r) => ({ label: `${r.RanchNbr}-${r.RanchName}`, value: r.RanchNbr })),
                            'value'
                        )
                    }}
                    initialFilter={{
                        producingArea: getUniqueListBy(
                            filteredList.map((p) => ({
                                label: `${p.ProducingAreaCode}-${p.ProducingAreaName}`,
                                value: p.ProducingAreaCode
                            })),
                            'value'
                        ).find((p) => p.value === params.get('ProducingAreaCode')),
                        grower: getUniqueListBy(
                            filteredList.map((g) => ({ label: `${g.GrowerNbr}-${g.GrowerName}`, value: g.GrowerNbr })),
                            'value'
                        ).find((p) => p.value === params.get('GrowerNbr')),
                        ranch: getUniqueListBy(
                            filteredList.map((r) => ({ label: `${r.RanchNbr}-${r.RanchName}`, value: r.RanchNbr })),
                            'value'
                        ).find((p) => p.value === params.get('RanchNbr'))
                    }}
                    optionsLoading={{
                        producingArea: loadingOptions,
                        ranch: loadingOptions,
                        grower: loadingOptions
                    }}
                    goBack={`/SettlementWorkbench?poolWeek=${poolWeek}`}
                    onError={(message) => props.showToast(message)}
                    onDetailFilterChange={(filters) => {
                        if (!firstRender.current) {
                            let prevParams = {};
                            params.forEach((value, key) => {
                                prevParams = { ...prevParams, [key]: value };
                            });
                            setSearchParams(
                                {
                                    ...prevParams,
                                    ProducingAreaCode: filters.producingArea?.value,
                                    RanchNbr: filters.ranch?.value,
                                    GrowerNbr: filters.grower?.value
                                },
                                { replace: true }
                            );
                            setFilters({
                                ProducingAreaCode: filters.producingArea?.value,
                                RanchNbr: filters.ranch?.value,
                                GrowerNbr: filters.grower?.value
                            });
                        }
                    }}
                />
            </div>
        </DrcWrapper>
    );
}

const mapStateToProps = ({ settlementWorkbenchReducer, rootReducer }) => ({
    total: settlementWorkbenchReducer.totalRecords,
    pageTitle: rootReducer.pageTitle,
    records: settlementWorkbenchReducer.records,
    showToast: rootReducer.showToast
});

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showToast: (message, type) => dispatch(showToast(message, type)),
    setSettlementWorkbenchData: (data) => dispatch(setSettlementWorkbenchData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withRouter(WorkbenchDetails)));
